<template>
  <div class="pa-10">
    <admins />
  </div>
</template>
<script>
import admins from "@/components/admins.vue";
export default {
  components: {
    admins,
  },
  data() {
    return {};
  },
};
</script>
<style></style>
