<!-- 
System: Whistle It
Developer: Jazib Nasir
Date: 17-06-2021 
Organization: Programmer's Force
Purpose: Component for displaying Admins data in super admin
-->

<template>
  <div>
    <v-sheet
      color="white"
      :height="$vuetify.breakpoint.xs ? '130' : '83'"
      width="100%"
      :class="$vuetify.breakpoint.xs ? 'd-flex flex-column' : 'd-flex '"
      class="px-8 py-8"
    >
      <!---------------------------------------------Search Textfield------------------------------------------------>
      <v-text-field
        v-model="search"
        class="mt-n3"
        style="max-width: 200px"
        prepend-inner-icon="mdi-magnify"
        label="Search Admin"
        placeholder="Search Admin"
        single-line
        outlined
        dense
        autofocus
        color="admin-primary"
        hide-details
      >
      </v-text-field>
      <v-spacer></v-spacer>
      <v-btn color="#390c2e" dark @click="addAdminModal = true"
        >+ Add new admin</v-btn
      >
    </v-sheet>

    <v-skeleton-loader
      v-if="firstLoad"
      :loading="loading"
      type="table-thead,table-tbody"
    ></v-skeleton-loader>
    <!------------------------------------------------Data Table------------------------------------------------>
    <v-data-table
      :headers="headers"
      :items="admins"
      v-show="!firstLoad"
      class="shadow-class tableRowBg"
      :search="search"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      @page-count="pageCount = $event"
      :item-class="rowClass"
      :loading="loading"
    >
      <!-------------------------------------------- Dialog to Edit Comapny -------------------------------------->
      <template v-slot:top>
        <v-dialog v-model="dialog" persistent max-width="500px">
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
              <v-spacer></v-spacer>
              <v-icon @click="dialog = false">mdi-close</v-icon>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="12">
                    <!-------------------------------------------- Name textfield ----------------------------------------------->
                    <v-text-field
                      v-model="editedItem.name"
                      label="Company name"
                      outlined
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!---------------------------------------------- Buttons ---------------------------------------------------->
              <v-btn class="white--text" color="admin-primary" @click="close">
                Cancel
              </v-btn>
              <v-btn class="white--text" color="admin-primary" @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!------------------------------------- Dialogue to delete company ---------------------------------------->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >Are you sure you want to remove this admin?</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="admin-primary"
                class="white--text"
                @click="closeDelete"
                >Cancel</v-btn
              >
              <v-btn
                color="admin-primary"
                class="white--text"
                @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!---------------------------------- dialog to add new admin ---------------------------------->
        <v-dialog
          v-model="addAdminModal"
          max-width="500px"
          overlay-color="modal-overlay"
          overlay-opacity="0.78"
        >
          <v-card>
            <v-card-title class="text-break d-flex justify-center cardTitle">
              <span class="dialog-header-text">Add New Admin</span>
            </v-card-title>
            <v-container>
              <!---------------------------------------------- Name Textfield ---------------------------------------------->
              <v-text-field
                placeholder="Enter Name*"
                label="Enter Name*"
                outlined
                class="mt-5"
                v-model="adminName"
                id="TP-addTeam"
                autofocus
                :rules="[rules.required, rules.minName, rules.maxName]"
                dense
                color="admin-primary"
              ></v-text-field>
              <!---------------------------------------------- Email Textfield ---------------------------------------------->
              <v-text-field
                placeholder="Enter Email*"
                label="Enter Email*"
                outlined
                class="mt-5"
                v-model="adminEmail"
                :rules="[rules.required, rules.email]"
                dense
                color="admin-primary"
              ></v-text-field>
              <!---------------------------------------------- Password Textfield ---------------------------------------------->
              <v-text-field
                placeholder="Enter Password*"
                label="Enter Password*"
                outlined
                class="mt-5"
                v-model="adminPassword"
                :rules="[rules.required, rules.min, rules.max]"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                dense
                :type="showPassword ? 'text' : 'password'"
                color="admin-primary"
                @click:append="showPassword = !showPassword"
              ></v-text-field>
            </v-container>
            <v-divider></v-divider>

            <v-card-actions class="py-5">
              <v-spacer></v-spacer>
              <v-btn
                color="text-message"
                text
                @click="addAdminModal = false"
                id="TP-close-modal"
                >Cancel</v-btn
              >
              <v-btn
                id="TP-save-modal"
                class="admin-primary white--text disable"
                width="95px"
                medium
                :disabled="isFormNotvalid"
                @click="addAdmin"
                color="admin-primary"
                >Save</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <!------------------------------------ slot for Company creation Date -------------------------------------->
      <template v-slot:[`item.created_at`]="{ item }">
        <div :class="$vuetify.breakpoint.xs ? 'my-3' : 'my-5'">
          {{ new Date(item.created_at).toLocaleString("en-US", options) }}
        </div>
      </template>
      <!--------------------------------------- slot for Company  Actions ----------------------------------------->
      <template v-slot:[`item.actions`]="{ item }">
        <!------------------------------------------ Delete Admin ---------------------------------------------->

        <v-tooltip top color="admin-tooltip-color">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              v-bind="attrs"
              v-on="on"
              class="admin-primary--text"
              @click="deleteItem(item)"
            >
              mdi-delete
            </v-icon>
          </template>
          <span>Remove Admin</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn class="white--text" color="admin-primary" @click="initialize">
          Reset
        </v-btn>
      </template>
    </v-data-table>
    <div
      class="d-flex justify-space-between search-container"
      :class="$vuetify.breakpoint.xs ? 'flex-column ' : 'py-4 px-8'"
    >
      <span
        class="d-flex justify-start"
        :class="$vuetify.breakpoint.xs ? 'ml-15 ' : ''"
      >
        <v-subheader class="pb-2"> Rows per Page </v-subheader>
        <v-select
          :items="perPage"
          dense
          color="#432662"
          @change="
            setPerPage($event);
            selected = true;
          "
          class="select-per-page"
          style=""
        >
          <template v-slot:append>
            <v-icon small color="admin-primary" class="py-1"
              >mdi-chevron-down</v-icon
            >
          </template>
          <template v-slot:label>
            <span
              style="font-size: 12px"
              class="pl-3"
              :class="selected ? 'd-none' : ''"
              >{{ itemsPerPage }}</span
            >
          </template>
        </v-select>
      </span>
      <!----------------------------------------------- PAGINATION ------------------------------------------------->
      <v-pagination
        color="#432662"
        id="#pagination"
        v-model="page"
        :length="pageCount"
        size="small"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
        total-visible="5"
      ></v-pagination>
    </div>
    <Snackbar
      :snackbar.sync="snackbar"
      :message="message"
      :color="snackbarColor"
    />
  </div>
</template>
<!--------------------------------------------------Script--------------------------------------------------->
<script>
import { mapActions, mapGetters } from "vuex";
import Snackbar from "@/components/Snacbar";
export default {
  components: {
    Snackbar,
  },
  data: () => ({
    signoutErrorSnackText: "Your session has expired !!!",
    signoutErrorSnack: false,
    firstLoad: true,
    loading: true,
    selected: false,
    search: "",
    dialog: false,
    addAdminModal: false,
    adminName: "",
    adminEmail: "",
    adminPassword: "",
    dialogDelete: false,
    showPassword: false,
    message: "",
    snackbarColor: "",
    snackbar: false,

    rules: {
      required: (value) => !!value || "Required.",
      minName: (v) => v.length >= 3 || "Min 3 characters",
      maxName: (v) => v.length <= 50 || "Max 50 characters",
      min: (v) => v.length >= 6 || "Min 6 characters",
      max: (v) => v.length <= 128 || "Max 128 characters",
      email: (v) =>
        /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/.test(v) ||
        "E-mail must be valid",
    },
    //options for date formate
    options: {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    },
    //PAGINATION VARIABLES
    page: 1,
    pageCount: 0,
    itemsPerPage: 25,
    perPage: [25, 50, 100, 250, 500, 750, 1000],
    //---------------------------------------------------Headers------------------------------------------------->
    headers: [
      {
        text: "Name",
        align: "start",
        value: "full_name",
        class: "admin-primary--text tableHeaderBg",
      },
      {
        text: "Email",
        value: "email",
        class: "admin-primary--text tableHeaderBg",
      },

      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "admin-primary--text tableHeaderBg",
      },
    ],

    editedIndex: -1,
    editedItem: {
      name: "",
    },
    defaultItem: {
      name: "",
    },
    removedUser: "",
  }),
  //---------------------------------------------Computed----------------------------------------------->
  computed: {
    ...mapGetters(["getAdmins", "getTotalRows"]),
    //Changing Dialog Box Title
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    //computed properi
    admins() {
      return this.getAdmins;
    },
    isFormNotvalid() {
      return (
        this.adminName.length < 3 ||
        this.adminName.length > 50 ||
        this.adminEmail.length == 0 ||
        /[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$/.test(this.adminEmail) ==
          false ||
        this.adminPassword.length < 6
      );
    },
  },

  //-----------------------------------------------methods---------------------------------------------->
  methods: {
    ...mapActions(["Admins", "addNewAdmin", "removeAdmin"]),
    initialize() {},
    deleteItem(item) {
      this.removedUser = item;
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.removeAdmin(this.removedUser._id).then(
        (response) => {
          if (response.status == 200) {
            this.addAdminModal = false;
            this.snackbar = true;
            this.message = "Admin removed";
            this.snackbarColor = "success";
          }
        },
        (error) => {
          this.addAdminModal = false;
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            this.snackbar = true;
            this.message = error.response.data.error;
          } else {
            this.snackbar = true;
            this.message = "Something went wrong.";
          }
        }
      );
      this.dialogDelete = false;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.admins[this.editedIndex], this.editedItem);
      } else {
        this.admins.push(this.editedItem);
      }
      this.close();
    },
    setPerPage(perPageEntries) {
      this.itemsPerPage = perPageEntries;
    },
    rowClass() {
      return "admin-table-text";
    },
    testFunction: function () {
      setTimeout(() => {
        if (this.firstLoad) this.firstLoad = false;
        this.loading = false;
      }, 2000);
    },
    // this function is responible for adding new admin
    addAdmin() {
      var requestObj = {
        full_name: this.adminName,
        email: this.adminEmail,
        password: this.adminPassword,
      };
      this.addNewAdmin(requestObj).then(
        (response) => {
          if (response.status == 200) {
            this.addAdminModal = false;
            this.snackbar = true;
            this.message = "Admin added";
            this.snackbarColor = "success";
          }
        },
        (error) => {
          this.addAdminModal = false;
          if (
            error.response &&
            error.response.data &&
            error.response.data.error
          ) {
            this.snackbar = true;
            this.message = error.response.data.error;
          } else {
            this.snackbar = true;
            this.message = "Something went wrong.";
          }
        }
      );
    },
  },
  //------------------------------------------------Mounted------------------------------------------------>
  async mounted() {
    await this.Admins().then(
      () => {},
      (error) => {
        console.log(error);
        if (error.response.status == 401) {
          this.signoutErrorSnack = true;
          let vm = this;
          setTimeout(() => {
            vm.$router.push("/");
          }, 200);
        }
      }
    );
    this.testFunction();
    this.initialize();
  },
  //------------------------------------------------Watchers------------------------------------------------>
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    addAdminModal(newValue) {
      if (newValue) {
        this.adminName = "";
        this.adminEmail = "";
        this.adminPassword = "";
      }
    },
  },
};
</script>
<!---------------------------------------------------Style------------------------------------------------>
<style scoped>
.tableHeaderBg {
  color: #eeeff7;
}
#pagination .v-pagination .v-pagination__item,
.v-pagination .v-pagination__more,
.v-pagination {
  height: 21px !important;
  min-width: 20px !important;
  font-size: 12px !important;
}
#pagination .v-pagination__navigation .v-icon {
  font-size: 16px !important;
}
#pagination .v-pagination__navigation .v-pagination__item {
  font-size: 16px !important;
  height: 20px;
  width: 20px;
  color: #eeeff7;
}
#pagination .v-pagination__navigation {
  height: 21px !important;
  width: 20px !important;
}
.v-pagination__item .v-pagination__item--active {
  height: 12px !important;
}
.v-pagination__navigation {
  height: 12px !important;
  width: 5px !important;
}
.select-per-page {
  box-sizing: border-box;
  height: 27.98px !important;
  max-width: 53px !important;
  border: 1px solid rgba(67, 38, 98, 0.13);
  border-radius: 2px;
  font-size: 12px !important;
  color: rgba(67, 38, 98, 0.13) !important;
}
</style>
