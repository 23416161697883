<!-- 
System: Whistle It
Developer: Hassan Ali
Date: 17-06-2021 
Organization: Whistle it
Purpose: Component for displaying Teams data
-->

<template>
  <v-snackbar
    v-model="localSnackbar"
    :timeout="2000"
    right
    top
    color="white"
    :content-class="
      color == 'success' ? 'snackbar-success-border' : 'snackbar-error-border'
    "
  >
    <div class="d-flex">
      <img
        v-if="color == 'success'"
        height="18"
        width="18"
        :src="successIcon"
      />
      <v-icon v-else color="error" size="20">mdi-close-circle</v-icon>

      <span v-if="color == 'success'" class="pl-2 text--secondary"
        ><b>Success! </b>{{ message }}</span
      >
      <span v-else class="pl-2 text--secondary"
        ><b>Error! </b>{{ message }}</span
      >

      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="updateSnacbarState" x-small icon>
        <v-icon small color="#7A7D8A">mdi-close</v-icon>
      </v-btn>
    </div>
  </v-snackbar>
</template>
<script>
export default {
  props: {
    snackbar: {
      type: Boolean,
      defualt: false,
    },
    message: {
      type: String,
    },
    color: {
      type: String,
      default: "success",
    },
  },
  data() {
    return {
      localSnackbar: false,
      successIcon: require("@/assets/icons/Online .svg"),
    };
  },

  methods: {
    updateSnacbarState: function () {
      this.$emit("update:snackbar", false);
    },
  },
  watch: {
    //assign snacbar prop to local snacbar data variable
    snackbar: function () {
      this.localSnackbar = this.snackbar;
    },
    localSnackbar: function () {
      if (this.localSnackbar == false) {
        this.$emit("update:snackbar", false);
      }
    },
  },
};
</script>
<style>
.snackbar-error-border {
  /* box-shadow: none !important; */
  border-bottom: 5px solid red;
}
.snackbar-success-border {
  border-bottom: 5px solid #03d7a0;
}
</style>
